import { CreateProjectSchemaType, ProjectSchemaType } from '@/src/@types/schemas/projects/project';
import { apiWithToken } from '@/src/lib/index';
import { VesselBasicsSchemaType, VesselSchemaType } from '@/src/@types/schemas/projects/vessel';
import { ActivityEstimationSchemaType } from '@/src/@types/schemas/projects/vesselActivityEstimation';
import {
  CreatePeriodSchemaType,
  PeriodSchemaType,
} from '@/src/@types/schemas/projects/vesselPeriod';
import { CreateLogSchemaType, LogSchemaType } from '@/src/@types/schemas/projects/log';
import { StakeholderSchemaType } from '@/src/@types/schemas/projects/stakeholder';
import { VesselBadgeTypesSchema } from '@/src/@types/schemas/projects/badgeMenu';

// Get vessel badges
async function getBadgesForVessel({
  vesselId,
}: {
  vesselId: string;
}): Promise<VesselBadgeTypesSchema | undefined> {
  return apiWithToken.get(`/vessels/${vesselId}/badges`).then((res) => res.data);
}

// Projects list
const getProjectsList = ({ active = true }: { active: boolean }) =>
  apiWithToken.get(`/projects?active=${active}`).then((res) => res.data);

// Projects CRUD
const createProject = (values: CreateProjectSchemaType) =>
  apiWithToken.post(`/projects`, values).then((res) => res.data);

const patchProject = async ({
  values,
  projectId,
}: {
  values: Partial<ProjectSchemaType>;
  projectId: string;
}) =>
  apiWithToken
    .patch(`/projects/${projectId}`, values, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })
    .then((res) => res.data);

const getProject = ({ projectId }: { projectId: string }): Promise<ProjectSchemaType> =>
  apiWithToken.get(`/projects/${projectId}`).then((res) => res.data);

// Project delete
const deleteProject = ({ projectId }: { projectId: string }): Promise<ProjectSchemaType> =>
  apiWithToken.delete(`/projects/${projectId}`).then((res) => res.data);

// Vessels CRUD
const addVesselToProject = ({
  vesselId,
  projectId,
}: {
  vesselId: string;
  projectId: string;
}): Promise<ProjectSchemaType['vessels']> =>
  apiWithToken.post(`/projects/${projectId}/vessels`, { id: vesselId }).then((res) => res.data);

const patchVessel = async ({
  values,
  vesselId,
  projectId,
}: {
  values: Partial<VesselBasicsSchemaType>;
  vesselId: string;
  projectId: string;
}) =>
  apiWithToken
    .patch(`/projects/${projectId}/vessels/${vesselId}`, values, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })
    .then((res) => res.data);

const deleteVesselFromProject = ({
  vesselId,
  projectId,
}: {
  vesselId: string;
  projectId: string;
}) => apiWithToken.delete(`/projects/${projectId}/vessels/${vesselId}`).then((res) => res.data);

// Vessel ActivePeriods CRUD
const addActivePeriodToVessel = ({
  vesselId,
  projectId,
  activePeriod,
}: {
  vesselId: string;
  projectId: string;
  activePeriod: CreatePeriodSchemaType;
}): Promise<PeriodSchemaType> =>
  apiWithToken
    .post(`/projects/${projectId}/vessels/${vesselId}/activeperiods`, activePeriod)
    .then((res) => res.data);

const deleteActivePeriodFromVessel = ({
  vesselId,
  projectId,
  activePeriodId,
}: {
  vesselId: string;
  projectId: string;
  activePeriodId: PeriodSchemaType['id'];
}) =>
  apiWithToken
    .delete(`/projects/${projectId}/vessels/${vesselId}/activeperiods/${activePeriodId}`)
    .then((res) => res.data);

const patchActivePeriod = ({
  vesselId,
  projectId,
  activePeriodId,
  values,
}: {
  vesselId: string;
  projectId: string;
  activePeriodId: PeriodSchemaType['id'];
  values: Partial<PeriodSchemaType>;
}) =>
  apiWithToken
    .patch(`/projects/${projectId}/vessels/${vesselId}/activeperiods/${activePeriodId}`, values, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })
    .then((res) => res.data);

// Vessel ActivityEstimations
const initVesselActivityEstimations = ({
  projectId,
  vesselId,
}: {
  projectId: string;
  vesselId: string;
}): Promise<VesselSchemaType['activityEstimations']> =>
  apiWithToken
    .get(`/projects/${projectId}/vessels/${vesselId}/activityestimations`)
    .then((res) => res.data);

const patchActivityEstimations = ({
  vesselId,
  projectId,
  activityEstimations,
}: {
  vesselId: string;
  projectId: string;
  activityEstimations: ActivityEstimationSchemaType[];
}) =>
  apiWithToken
    .patch(`/projects/${projectId}/vessels/${vesselId}/activityestimations`, activityEstimations, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((res) => res.data);

// Project logs CRUD
const getProjectLogs = ({ projectId }: { projectId: string }) =>
  apiWithToken.get(`/projects/${projectId}/logs`).then((res) => res.data);

const addLogToProject = ({
  projectId,
  log,
}: {
  projectId: string;
  log: CreateLogSchemaType;
}): Promise<LogSchemaType> =>
  apiWithToken.post(`/projects/${projectId}/logs`, log).then((res) => res.data);

const putLogToProject = ({
  projectId,
  logId,
  log,
}: {
  projectId: string;
  logId: string;
  log: CreateLogSchemaType;
}) => apiWithToken.put(`/projects/${projectId}/logs/${logId}`, log).then((res) => res.data);

const deleteLogFromProject = ({ projectId, logId }: { projectId: string; logId: string }) =>
  apiWithToken.delete(`/projects/${projectId}/logs/${logId}`).then((res) => res.data);

// Maress Vessel search
async function getVessels({ inFleet, ownVessel }: { inFleet?: boolean; ownVessel?: boolean }) {
  return apiWithToken
    .get(`/vessels`, {
      params: {
        inFleet,
        ownVessel,
      },
    })
    .then((res) => res.data);
}

// Vessels' positions data within a project
const getProjectVesselsPositions = async ({ projectId }: { projectId: string }) =>
  apiWithToken.get(`/projects/${projectId}/positions`).then((res) => res.data);

// Vessels' weather data within a project
const getProjectVesselsWeather = async ({ projectId }: { projectId: string }) =>
  apiWithToken.get(`/projects/${projectId}/weather`).then((res) => res.data);

// Vessels' emissions data within a project
// OPTIONAL PARAMS: ?period=day,month,year
const getProjectVesselEmissionsByDay = async ({
  projectId,
  vesselId,
}: {
  projectId: string;
  vesselId: string;
}) =>
  apiWithToken
    .get(`/projects/${projectId}/vessels/${vesselId}/emissions?period=day`)
    .then((res) => res.data);

// All vessels emissions
const getProjectEmissions = async ({ projectId }: { projectId: string }) =>
  apiWithToken.get(`/projects/${projectId}/emissions`).then((res) => res.data);

// Get static activity types for projectsApi
const getStaticActivities = () => apiWithToken.get(`/types/activities`).then((res) => res.data);

// Stakeholders
async function modifyStakeholders(projectId: string, values: Partial<StakeholderSchemaType>[]) {
  const response = await apiWithToken.put(`/projects/${projectId}/stakeholders`, values);
  return response.data;
}

async function requestVesselAccess({
  projectId,
  vesselId,
  emails,
}: {
  projectId: string;
  vesselId: string;
  emails: string[];
}) {
  return apiWithToken
    .post(`/projects/${projectId}/vessels/${vesselId}/approvals`, { emails })
    .then((res) => res.data);
}

export default {
  getStaticActivities,
  getBadgesForVessel,
  getProjectsList,
  createProject,
  patchProject,
  getProject,
  deleteProject,
  getProjectLogs,
  addLogToProject,
  putLogToProject,
  deleteLogFromProject,
  addVesselToProject,
  patchVessel,
  deleteVesselFromProject,
  addActivePeriodToVessel,
  deleteActivePeriodFromVessel,
  patchActivePeriod,
  initVesselActivityEstimations,
  patchActivityEstimations,
  getProjectVesselsWeather,
  getProjectVesselsPositions,
  getProjectVesselEmissionsByDay,
  getProjectEmissions,
  searchVesselsList: getVessels,
  modifyStakeholders,
  requestVesselAccess,
};
