import React, { ElementType } from 'react';
import styled from 'styled-components';
import { screenSizes } from '@/styles/variables';

type Props = {
  children: React.ReactNode;
  secondLevel?: boolean;
  backgroundColor?: string;
  as?: ElementType;
  noPadding?: boolean;
  noMargin?: boolean;
  className?: string;
};

const CardWrapper: React.FC<Props> = ({
  children,
  secondLevel = false,
  backgroundColor,
  as: Tag = 'div',
  noPadding = false,
  noMargin = false,
  className,
}: Props) => {
  return (
    <Wrapper
      as={Tag}
      className={className}
      secondLevel={secondLevel}
      backgroundColor={backgroundColor}
      noPadding={noPadding}
      noMargin={noMargin}
    >
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  secondLevel?: boolean;
  backgroundColor?: string;
  noPadding?: boolean;
  noMargin?: boolean;
}>`
  padding: ${({ noPadding, theme }) => (noPadding ? theme.space.none : theme.space.md)};
  margin-bottom: ${({ noMargin, theme }) => (noMargin ? theme.space.none : theme.space.md)};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : ({ theme }) => theme.colors.black600};
  border-radius: 0.75rem;
  ${({ secondLevel, theme }) =>
    secondLevel &&
    `
      background-color: ${theme.colors.black500};
  `}
  img {
    max-width: 100%;
    height: auto;
  }
  @media (min-width: ${screenSizes.screenWidthSmall}) {
    padding: ${({ noPadding, theme }) => (noPadding ? theme.space.none : theme.space.lg)};
  }
`;

export default CardWrapper;
