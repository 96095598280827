import useSWR from 'swr';
import { errorInterceptor } from '@/src/utils/errorHelper';
import projectsApi from '@/src/lib/projectsApi';
import { VesselSearchSchemaType } from '@/src/@types/schemas/projects/vesselSearch';

type Props = {
  shouldFetch: boolean;
  userId?: string;
  inFleet?: boolean;
  ownVessel?: boolean;
};

export default function useVesselSearch({ shouldFetch, userId, inFleet, ownVessel }: Props) {
  const { data, error, isLoading } = useSWR(
    shouldFetch ? ['api/vessels', userId] : null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (): Promise<VesselSearchSchemaType[]> =>
      projectsApi.searchVesselsList({
        inFleet,
        ownVessel,
      }),
    {
      revalidateOnFocus: false,
      onSuccess: (data) => {
        localStorage.setItem('vessels', JSON.stringify(data));
      },
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Never retry on 404.
        const err = errorInterceptor(error);
        if (err?.status === 404) return;

        // Only retry up to five times.
        if (retryCount >= 5) return;

        // Retry after 5 seconds.
        setTimeout(() => {
          revalidate({ retryCount });
        }, 5000);
      },
    }
  );

  return {
    isLoading,
    error,
    data,
  };
}
