import React from 'react';
import styled from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';

type Props = LinkProps & {
  children: React.ReactNode;
};

const TextLink: React.FC<Props> = ({ to, children, className }: Props) => {
  return (
    <Wrapper className={className} to={to}>
      {children}
    </Wrapper>
  );
};
const Wrapper = styled(Link)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space.sm};
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.brand};
  &:hover {
    color: ${({ theme }) => theme.colors.brandHover};
  }
  svg {
    width: 1.5rem;
  }
`;

export default TextLink;
