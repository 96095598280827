import useSWR from 'swr';
import { getUserInfo } from '@/src/lib/inputModuleApi';
import { errorInterceptor } from '@/src/utils/errorHelper';

export default function useUserInfo() {
  const { data, error, isLoading } = useSWR('api/user-info', getUserInfo, {
    revalidateOnFocus: false,

    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
      // Never retry on 404.
      const err = errorInterceptor(error);
      if (err?.status === 404) return;

      // Only retry up to five times.
      if (retryCount >= 5) return;

      // Retry after 5 seconds.
      setTimeout(() => {
        revalidate({ retryCount });
      }, 5000);
    },
  });
  return { data, isLoading, error };
}
