import React from 'react';
import { LinkProps } from 'react-router-dom';
import TextLink from '@/components/clickable/textLink/TextLink';
import { ReactComponent as OrangeArrow } from '@/assets/icons/orange-arrow.svg';
import styled from 'styled-components';

type Props = LinkProps & {
  text?: string;
};

const BackLink: React.FC<Props> = ({ to, text = 'Go back', className }: Props) => {
  return (
    <CustomTextLink to={to} className={className}>
      <>
        <OrangeArrow />
        {text}
      </>
    </CustomTextLink>
  );
};

const CustomTextLink = styled(TextLink)`
  color: ${({ theme }) => theme.colors.textSecondary};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export default BackLink;
