import { focusStyle } from '@/styles/variables';
import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';

export type SelectInputOption = {
  value: string;
  text: string;
};

type Props = InputHTMLAttributes<HTMLSelectElement> & {
  label: string;
  options: SelectInputOption[];
  id?: string;
  hasError?: boolean;
  errorMessage?: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  className?: string;
};

const Select = ({
  value,
  label,
  options,
  id,
  errorMessage,
  hasError,
  onChange,
  className,
  ...rest
}: Props): JSX.Element => {
  return (
    <Wrapper className={className} hasError={hasError}>
      <CustomSelect
        value={value}
        id={id}
        name={id}
        onChange={onChange}
        hasError={hasError}
        {...rest}
      >
        {options.map((item) => {
          return (
            <option value={item.value} key={`${item.text}-${item.value}`}>
              {item.text}
            </option>
          );
        })}
      </CustomSelect>
      <label htmlFor={id}>{label}</label>
      {hasError && errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ disabled?: boolean; hasError?: boolean }>`
  position: relative;
  width: 100%;
  min-width: fit-content;

  label {
    position: absolute;
    top: 0.3rem;
    left: 1rem;
    z-index: 10;
    font-size: 0.75rem;
    transition: all 0.1s ease;
    pointer-events: none;
    color: ${({ theme }) => theme.colors.black800};
    font-weight: bold;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10' fill='none' stroke='%235d6f7e' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='2.5 4 5 6.5 7.5 4'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    pointer-events: none;
  }

  ${({ hasError, theme }) =>
    hasError &&
    `
    label {
      color: ${theme.colors.warning} !important;
    }
  `}

  ${({ disabled, theme }) =>
    disabled &&
    `
       label {
          color: ${theme.colors.grey200};
        }
    `}
`;
const CustomSelect = styled.select<{
  hasError?: boolean;
  disabled?: boolean;
}>`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  padding: 1rem 1rem 0.3rem;
  margin-right: 3rem;
  width: 100%;
  height: 2.75rem;
  color: ${({ theme }) => theme.colors.black500};
  background-color: ${({ theme }) => theme.colors.white500};
  border: none;
  border-radius: 0.5rem;

  ${({ hasError, theme }) =>
    hasError &&
    `
      margin-bottom: ${theme.space.sm};
      background-color: ${theme.colors.warningHover};
  `}

  ${({ disabled, theme }) =>
    disabled &&
    `
     background-color: ${theme.colors.grey500};
  `}
  ${({ required, theme }) =>
    required &&
    `
      + label:after {
        content: ' *';
        color: ${theme.colors.warning};
    }
  `}
  &:focus:not([disabled]) {
    ${focusStyle};
  }
`;

const ErrorMessage = styled.span`
  display: block;
  width: 100%;
  margin-top: ${({ theme }) => theme.space.xs};
  color: ${({ theme }) => theme.colors.secondary500};
  font-size: 0.85rem;
`;

export default Select;
