import React, { useEffect, useState } from 'react';
import SubLayout from '@/components/subLayout/SubLayout';
import Select from '@/components/formElements/Select';
import styled from 'styled-components';
import CardWrapper from '@/components/cardWrapper/CardWrapper';
import { CenterContent } from '@/src/components';
import { useAuthContext } from '@/contexts/auth';
import useVesselSearch from '@/src/hooks/useVesselSearch';
import Loading from '@/components/loading/Loading';
import useBadgesForVessel from '@/src/hooks/useBadgesForVessel';
import {
  VesselBadgeTypesSchema,
  BadgeType,
  Badge,
  BadgeGrade,
} from '@/src/@types/schemas/projects/badgeMenu';
import useUserInfo from '@/src/hooks/useUserInfo';
import { apiWithToken } from '@/src/lib';

type SelectOptionType = {
  text: string;
  value: string;
};

enum BadgeGradeEnum {
  bronze,
  silver,
  gold,
}

const BadgeMenuPage: React.FC = () => {
  const { userId } = useAuthContext();
  const [selectedVesselId, setSelectedVesselId] = useState<string>('');
  const [roleName, setRoleName] = useState<string>('');
  const [vesselOptions, setVesselOptions] = useState<SelectOptionType[]>([]);
  const [vesselBadges, setVesselBadges] = useState<VesselBadgeTypesSchema>();

  // USER QUERY
  const { data: userData, isLoading: isUserLoading } = useUserInfo();

  // VESSEL QUERY
  const { data: returnedVessels, isLoading: isVesselsLoading } = useVesselSearch({
    shouldFetch: userId !== undefined,
    userId,
    inFleet: true,
  });

  // BADGE QUERY
  const { data: badges, isLoading: isBadgesLoading } = useBadgesForVessel({
    shouldFetch:
      selectedVesselId !== undefined && selectedVesselId !== null && selectedVesselId !== '',
    vesselId: selectedVesselId,
  });

  useEffect(() => {
    if (userData === undefined || returnedVessels === undefined || returnedVessels?.length === 0)
      return;

    const selectedVessel = returnedVessels.find((vessel) => vessel.id === userData.defaultVesselId);

    const defaultVesselOption = {
      value: userData.defaultVesselId,
      text:
        selectedVessel !== undefined && selectedVessel.name !== undefined
          ? selectedVessel.name
          : '',
    };

    const remVesselOptions = returnedVessels
      .filter((v) => v.id !== userData.defaultVesselId)
      .map((v) => ({ value: v.id, text: v.name }))
      .sort((a, b) => (a.text < b.text ? -1 : 1));
    setVesselOptions([defaultVesselOption, ...remVesselOptions]);
    setSelectedVesselId(defaultVesselOption.value);
    setRoleName(userData.roleName);
  }, [userData, returnedVessels]);

  useEffect(() => {
    if (badges === undefined) {
      return;
    }

    const badgesString = JSON.stringify(badges, null, 2);
    const parsedBadgeValues: VesselBadgeTypesSchema = JSON.parse(badgesString);

    setVesselBadges(parsedBadgeValues);
  }, [badges]);

  if (isUserLoading || isVesselsLoading || isBadgesLoading || !vesselBadges) {
    return <Loading fullScreen />;
  }

  // Hover-over text for badges is the value of badge_type description with the first instance of " x " replaced by
  // for non-owned badges: " <descriptionBronze>, <descriptionSilver> or <descriptionGold> "
  // For owned badges it is instead replaced with whichever of the three grades the badge has
  function getHoverOverText(badgeType: BadgeType, badgeGrade: BadgeGrade | null) {
    if (badgeGrade != null) {
      switch (badgeGrade.name) {
        case 'Bronze':
          return badgeType.description.replace(' x ', ' ' + badgeType.descriptionBronze + ' ');
        case 'Silver':
          return badgeType.description.replace(' x ', ' ' + badgeType.descriptionSilver + ' ');
        case 'Gold':
          return badgeType.description.replace(' x ', ' ' + badgeType.descriptionGold + ' ');
      }
    } else {
      return badgeType.description.replace(
        ' x ',
        ' ' +
          badgeType.descriptionBronze +
          ', ' +
          badgeType.descriptionSilver +
          ' or ' +
          badgeType.descriptionGold +
          ' '
      );
    }
  }

  type BadgeTypeListProps = {
    list: BadgeType[] | null;
    name: string;
    owns: boolean;
  };

  type BadgeListProps = {
    list: Badge[] | null;
    name: string;
    owns: boolean;
  };

  const isAdmin = roleName == 'Superuser';

  const updateBadge = async (badgeTypeId: number, add: boolean, grade: BadgeGradeEnum | null) => {
    if (vesselBadges) {
      const tempVesselBadges = structuredClone(vesselBadges);

      const url =
        '/vessels/' +
        selectedVesselId +
        '/badges/' +
        badgeTypeId +
        '/' +
        add +
        (grade != null ? '/' + grade : '');
      const gradeElement = await apiWithToken.post(url);

      if (add) {
        for (let index = 0; index < tempVesselBadges.absentBadgeTypes.length; index++) {
          const badgeType = tempVesselBadges.absentBadgeTypes[index];
          if (badgeType.id == badgeTypeId) {
            tempVesselBadges?.absentBadgeTypes.splice(index, 1);
            const badge = { badgeType: badgeType, badgeGrade: gradeElement.data };
            tempVesselBadges.presentBadgeTypes = [...tempVesselBadges.presentBadgeTypes, badge];
            break;
          }
        }
      } else {
        for (let index = 0; index < tempVesselBadges.presentBadgeTypes.length; index++) {
          const badge = tempVesselBadges.presentBadgeTypes[index];
          if (badge.badgeType.id == badgeTypeId) {
            tempVesselBadges?.presentBadgeTypes.splice(index, 1);
            tempVesselBadges.absentBadgeTypes = [
              ...tempVesselBadges.absentBadgeTypes,
              badge.badgeType,
            ];
            break;
          }
        }
      }

      if (tempVesselBadges) {
        tempVesselBadges.presentBadgeTypes.sort((a, b) =>
          a.badgeType.name.localeCompare(b.badgeType.name)
        );
        tempVesselBadges.absentBadgeTypes.sort((a, b) => a.name.localeCompare(b.name));
      }

      setVesselBadges(tempVesselBadges);
    }
  };

  // Badges vessel possess, using Badge containing both BadgeType and BadgeGrade elements
  const BadgeList: React.FC<BadgeListProps> = ({ list, name, owns }) => {
    if (!list || list.length === 0) {
      return <div></div>;
    }
    return (
      <div className="content-body">
        <BadgeGridWrapper>
          {list.map((badge, index) => (
            <BadgeGridItem
              key={name + '-' + index}
              title={getHoverOverText(badge.badgeType, badge.badgeGrade)}
            >
              <div>{renderBadge(badge.badgeType, badge.badgeGrade, owns)}</div>
              {isAdmin && !badge.badgeType.isAutomatic && (
                <button onClick={() => updateBadge(badge.badgeType.id, false, null)}>
                  Remove Badge from Vessel
                </button>
              )}
              <div>{badge.badgeType.name}</div>
            </BadgeGridItem>
          ))}
        </BadgeGridWrapper>
      </div>
    );
  };
  // Badges vessel does not possess, using BadgeType elements
  const BadgeTypeList: React.FC<BadgeTypeListProps> = ({ list, name, owns }) => {
    if (!list || list.length === 0) {
      return <div></div>;
    }
    return (
      <div className="content-body">
        <BadgeGridWrapper>
          {list.map((badge, index) => (
            <BadgeGridItem key={name + '-' + index} title={getHoverOverText(badge, null)}>
              <div>{renderBadge(badge, null, owns)}</div>
              {isAdmin &&
                !badge.isAutomatic &&
                !badge.descriptionBronze &&
                !badge.descriptionSilver &&
                !badge.descriptionGold && (
                  <button onClick={() => updateBadge(badge.id, true, null)}>
                    Add Badge to Vessel
                  </button>
                )}
              {isAdmin &&
                !badge.isAutomatic &&
                (badge.descriptionBronze || badge.descriptionSilver || badge.descriptionGold) && (
                  <div>
                    <button onClick={() => updateBadge(badge.id, true, BadgeGradeEnum.bronze)}>
                      Bronze
                    </button>
                    <button onClick={() => updateBadge(badge.id, true, BadgeGradeEnum.silver)}>
                      Silver
                    </button>
                    <button onClick={() => updateBadge(badge.id, true, BadgeGradeEnum.gold)}>
                      Gold
                    </button>
                  </div>
                )}
              <div>{badge.name}</div>
            </BadgeGridItem>
          ))}
        </BadgeGridWrapper>
      </div>
    );
  };

  // Renders singular badge, with border rendered below the badge itself if provided
  const renderBadge = (badge: BadgeType, badgeGrade: BadgeGrade | null, owns: boolean) => {
    try {
      const badgePath = '/icons/badge/' + badge.imagePath;
      const greyscale = (owns ? 0 : 100) + '%';

      return (
        <div className="item" style={{ position: 'relative', height: '150px' }}>
          <img
            src={badgePath}
            alt=""
            style={{
              filter: 'grayscale(' + greyscale + ')',
              width: '150px',
              height: '150px',
              position: 'absolute',
              top: '0px',
              left: '-0px',
            }}
          />
          {badgeGrade && (
            <img
              src={'/icons/badge/' + badgeGrade.imagePath}
              alt=""
              style={{
                width: '150px',
                height: '150px',
                position: 'absolute',
                top: '0px',
                left: '-0px',
              }}
            />
          )}
        </div>
      );
    } catch (error) {
      console.error('Error loading SVG:', error);
      return <p>SVG not found</p>;
    }
  };

  return (
    <SubLayout hideFooter={true} hasMaxWidth={true}>
      <Wrapper>
        <PageHeader>
          <ContentLeft>
            <p style={{ fontWeight: 'bold', fontSize: '2rem' }}>Badge Menu</p>
          </ContentLeft>
          <ContentRight>
            <Select
              label="Select vessel"
              options={vesselOptions}
              value={selectedVesselId !== null ? selectedVesselId : ''}
              onChange={({ target }) => {
                setSelectedVesselId(target.value);
              }}
            />
          </ContentRight>
        </PageHeader>
        <PageContent>
          <ContentHeader>Badges of Vessel</ContentHeader>
          <ContentBody>
            <BadgeList
              list={vesselBadges ? vesselBadges.presentBadgeTypes : null}
              name={'Present'}
              owns={true}
            />
          </ContentBody>
        </PageContent>
        <PageContent>
          <ContentHeader>Other Badges</ContentHeader>
          <ContentBody>
            <BadgeTypeList
              list={vesselBadges ? vesselBadges.absentBadgeTypes : null}
              name={'Absent'}
              owns={false}
            />
          </ContentBody>
        </PageContent>
      </Wrapper>
    </SubLayout>
  );
};

const ContentBody = styled.div`
  max-width: 1136px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.xxl};
`;

const BadgeGridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
`;

const BadgeGridItem = styled.div`
  padding: 15px;
  text-align: center;
  border-radius: 5px;
`;

const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap-reverse;
  gap: ${({ theme }) => theme.space.md};
  width: 100%;
  margin-bottom: ${({ theme }) => theme.space.xl};
`;

const PageContent = styled(CardWrapper)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.lg};
  padding: ${({ theme }) => theme.space.xl};
`;

const ContentRight = styled.div`
  min-width: fit-content;
  gap: ${({ theme }) => theme.space.md};
  display: flex;
  width: 20%;

  select {
    background-color: ${({ theme }) => theme.colors.black500};
    border: 1px solid ${({ theme }) => theme.colors.white500};
    padding: ${({ theme }) => theme.space.xl} 0 0.5rem ${({ theme }) => theme.space.md};
    height: fit-content;
  }

  label {
    top: 0.5rem;
  }

  select,
  label {
    color: ${({ theme }) => theme.colors.white500};
  }
`;

const ContentLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.space.md};
`;

const PageHeader = styled.header`
  padding: ${({ theme }) => theme.space.md} 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Wrapper = styled(CenterContent)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.xl};
`;

export default BadgeMenuPage;
