import styled from 'styled-components';
import { ReactNode } from 'react';
import Footer from '@/components/layout/footer/Footer';
import BackLink from '@/components/clickable/backLink/BackLink';

export default function SubLayout({
  children,
  backLink,
  backLinkText,
  hideFooter,
  hasMaxWidth = false,
}: {
  children: ReactNode;
  backLink?: string;
  backLinkText?: string;
  hideFooter?: boolean;
  hasMaxWidth?: boolean;
}) {
  return (
    <>
      <PageWrapper hasMaxWidth={hasMaxWidth}>
        <Header className={'hide-on-print'}>
          {backLink && <BackLink to={backLink}>{backLinkText}</BackLink>}
        </Header>
        <Main>{children}</Main>
        {!hideFooter && <Footer />}
      </PageWrapper>
    </>
  );
}

const PageWrapper = styled.div<{ hasMaxWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100vh;
  ${({ hasMaxWidth }) =>
    hasMaxWidth &&
    `
      margin: 0 auto;
      max-width: 1424px;
  `}
`;

const Main = styled.main`
  flex-grow: 1;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  padding: ${({ theme }) => theme.space.md};
`;
