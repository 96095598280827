import styled from 'styled-components';
import React from 'react';

const Footer: React.FC = () => {
  return (
    <Wrapper>
      <h4>Maress Project X</h4>
      <p>Contact information? Link back to Maress? Something?</p>
    </Wrapper>
  );
};

const Wrapper = styled.footer`
  padding: ${({ theme }) => theme.space.md};
  background-color: ${({ theme }) => theme.colors.grey500};
`;

export default Footer;
