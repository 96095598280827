import { apiWithToken } from '.';
import {
  CreateEntryRequestBodyType,
  CreateEntrySchemaType,
  EditEntryRequestBodyType,
  EntrySchemaType,
} from '../@types/schemas/inputModule/entry';
import { VDDSchemaType } from '../@types/schemas/inputModule/vdd';
import { AppConfigType } from '@/src/@types/schemas/inputModule/config';
import { UserInfoType } from '@/src/@types/schemas/inputModule/user-info';

async function getConfig(): Promise<AppConfigType | undefined> {
  const response = await apiWithToken.get('/types/settings');
  return response.data;
}

type GetEntriesParams = {
  vesselId: string;
  limit?: number;
  monthsBack?: number;
};
// Get vessel entries
async function getEntries({
  vesselId,
  limit = 100,
  monthsBack = 12,
}: GetEntriesParams): Promise<VDDSchemaType[] | undefined> {
  const response = await apiWithToken.get(
    `/vessels/${vesselId}/vdd/entries?limit=${limit}&monthsBack=${monthsBack}`
  );
  return response.data;
}

// Create new entry
async function postEntry(vesselId: string, entries: CreateEntryRequestBodyType[]) {
  await apiWithToken.post(`/vessels/${vesselId}/vdd/entries/`, entries, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

// Update entry (patch)
async function patchEntry(vesselId: string, entryId: string, update: EditEntryRequestBodyType) {
  const response = await apiWithToken.patch(`/vessels/${vesselId}/vdd/entries/${entryId}`, update, {
    headers: {
      'Content-Type': 'application/merge-patch+json',
    },
  });
  return response.data;
}

// Delete entry
async function deleteEntry(
  vesselId: string,
  entryId: string,
  commentId: string | null
): Promise<void> {
  if (commentId == null) {
    await apiWithToken.delete(`/vessels/${vesselId}/vdd/entries/${entryId}/comments`);
  } else {
    await apiWithToken.delete(`/vessels/${vesselId}/vdd/entries/${entryId}/comments/${commentId}`);
  }
}

// Update entry (complete)
async function putEntry(
  vesselId: string,
  entryId: string,
  entry: CreateEntrySchemaType
): Promise<EntrySchemaType | undefined> {
  const response = await apiWithToken.put(`/vessels/${vesselId}/entries/${entryId}`, entry);
  return response.data;
}

// Get user details
async function getUserInfo(userId?: string): Promise<UserInfoType | undefined> {
  if (!userId) return;
  const response = await apiWithToken.get('/user-info');
  return await response.data;
}

export { getConfig, getEntries, patchEntry, postEntry, deleteEntry, putEntry, getUserInfo };
